import { useAppState } from '@/store';
import { selectChannel } from '@/modules/Channels';
import { FormItem, IFormInstance } from '@/components';
import { ChannelIcon, CopyrightIcon, HashIcon, LabelIcon, PhoneIcon, StarIcon, SupportIcon, UserIcon } from '@/icons';
import { Input, Select, TextArea } from '@/ui';
import { useTranslation } from 'react-i18next';
import { VariableDetector } from 'src/shared/ui/VariablesDetector';
import { useVariableDetector } from 'src/shared/ui/VariablesDetector/hooks/useVariableDetector';
import { VariableType } from 'src/shared/ui/VariablesDetector/VariableDetector.typedefs';
import { GeneralSettingsFormFields, TGeneralSettingsFormValues } from '../../helpers/types';
import { useGeneralSettingsFormRules } from '../../hooks';
import SettingBlock from '../SettingBlock/SettingBlock';
import { StyledForm } from './GeneralSettingsFormStyles';
import { ChannelType } from '@/models/channel.model';

type IGeneralSettingsFormProps = {
  form: IFormInstance;
  onFinish?: (values: Partial<TGeneralSettingsFormValues>) => void;
  initialValues?: Partial<TGeneralSettingsFormValues>;
};

const GeneralSettingsForm = ({ form, onFinish, initialValues }: IGeneralSettingsFormProps) => {
  const currentChannel = useAppState(selectChannel);
  const { t } = useTranslation(['common']);
  const rules = useGeneralSettingsFormRules();
  const { variables, selectVariableInInput, registerInputRef, handleInputChange } = useVariableDetector();

  return (
    <>
      <VariableDetector variables={variables} onSelectVariable={selectVariableInInput} />
      <StyledForm form={form} onFinish={onFinish} initialValues={initialValues}>
        <SettingBlock title={t('title_google_tag_management')} icon={<LabelIcon />}>
          <FormItem
            name={GeneralSettingsFormFields.GOOGLE_TAG_MANAGER}
            rules={rules[GeneralSettingsFormFields.GOOGLE_TAG_MANAGER]}
          >
            <TextArea
              ref={(ref) => registerInputRef(t('title_google_tag_management'), ref, VariableType.TEXT_AREA)}
              onChange={(e) => handleInputChange(t('title_google_tag_management'), e.target.value)}
              autoSize={{ minRows: 4, maxRows: 4 }}
            />
          </FormItem>
        </SettingBlock>
        <SettingBlock title={t('title_channel')} icon={<ChannelIcon />}>
          <FormItem name={GeneralSettingsFormFields.CHANNEL}>
            <Input disabled />
          </FormItem>
        </SettingBlock>
        <SettingBlock title={t('title_og_site_name')} icon={<ChannelIcon />}>
          <FormItem name={GeneralSettingsFormFields.META_OG_SITE_NAME}>
            <Input />
          </FormItem>
        </SettingBlock>
        <SettingBlock title={t('title_admin_email')} icon={<UserIcon />}>
          <FormItem name={GeneralSettingsFormFields.ADMIN_EMAILS} rules={rules[GeneralSettingsFormFields.ADMIN_EMAILS]}>
            <Select
              ref={(ref) => registerInputRef(t('title_admin_email'), ref, VariableType.SELECT)}
              onChange={(value) => handleInputChange(t('title_admin_email'), (value as []).join(', '))}
              dropdownStyle={{ display: 'none' }}
              mode="tags"
              suffixIcon={null}
            />
          </FormItem>
        </SettingBlock>
        <SettingBlock title={t('title_smtp')} icon={<HashIcon />}>
          <FormItem
            name={GeneralSettingsFormFields.SMTP_PORT}
            rules={rules[GeneralSettingsFormFields.SMTP_PORT]}
            label={t('label_smtp_port')}
          >
            <Input
              ref={(ref) => registerInputRef(t('label_smtp_port'), ref, VariableType.INPUT)}
              onChange={(e) => handleInputChange(t('label_smtp_port'), e.target.value)}
            />
          </FormItem>
          <FormItem
            name={GeneralSettingsFormFields.SMTP_HOST}
            rules={rules[GeneralSettingsFormFields.SMTP_HOST]}
            label={t('label_smtp_host')}
          >
            <Input
              ref={(ref) => registerInputRef(t('label_smtp_host'), ref, VariableType.INPUT)}
              onChange={(e) => handleInputChange(t('label_smtp_host'), e.target.value)}
            />
          </FormItem>
          <FormItem
            name={GeneralSettingsFormFields.SMTP_AUTH_USER}
            rules={rules[GeneralSettingsFormFields.SMTP_AUTH_USER]}
            label={t('label_smtp_username')}
          >
            <Input
              ref={(ref) => registerInputRef(t('label_smtp_username'), ref, VariableType.INPUT)}
              onChange={(e) => handleInputChange(t('label_smtp_username'), e.target.value)}
            />
          </FormItem>
          <FormItem
            name={GeneralSettingsFormFields.SMTP_AUTH_PASS}
            rules={rules[GeneralSettingsFormFields.SMTP_AUTH_PASS]}
            label={t('label_smtp_password')}
          >
            <Input
              ref={(ref) => registerInputRef(t('label_smtp_password'), ref, VariableType.INPUT)}
              onChange={(e) => handleInputChange(t('label_smtp_password'), e.target.value)}
            />
          </FormItem>
        </SettingBlock>
        <SettingBlock title={t('title_copyright')} icon={<CopyrightIcon />}>
          <FormItem name={GeneralSettingsFormFields.COPYRIGHT} rules={rules[GeneralSettingsFormFields.COPYRIGHT]}>
            <Input
              ref={(ref) => registerInputRef(t('title_copyright'), ref, VariableType.INPUT)}
              onChange={(e) => handleInputChange(t('title_copyright'), e.target.value)}
            />
          </FormItem>
        </SettingBlock>
        {currentChannel?.type === ChannelType.PARTS && (
          <SettingBlock title={t('title_special_categories')} icon={<StarIcon />}>
            <FormItem
              name={GeneralSettingsFormFields.NEW_MACHINES_CATEGORY}
              label={t('label_special_categories_new_machnies')}
            >
              <Input onChange={(e) => handleInputChange(t('label_special_categories_new_machnies'), e.target.value)} />
            </FormItem>
            <FormItem
              name={GeneralSettingsFormFields.MONTHLY_SPECIAL_CATEGORY}
              label={t('label_special_categories_monthly_specials')}
            >
              <Input onChange={(e) => handleInputChange(t('label_special_categories_new_machnies'), e.target.value)} />
            </FormItem>
          </SettingBlock>
        )}
        <SettingBlock title={t('title_popular_products')} icon={<UserIcon />}>
          <FormItem name={GeneralSettingsFormFields.POPULAR_PRODUCTS}>
            <Select dropdownStyle={{ display: 'none' }} mode="tags" suffixIcon={null} maxCount={8} />
          </FormItem>
        </SettingBlock>
        <SettingBlock title={t('title_top_picks_of_the_month')} icon={<UserIcon />}>
          <FormItem name={GeneralSettingsFormFields.TOP_PICKS_OF_THE_MONTH}>
            <Select
              dropdownStyle={{ display: 'none' }}
              mode="tags"
              suffixIcon={null}
              maxCount={8}
              placeholder={t('placeholder_top_picks_of_the_month')}
            />
          </FormItem>
        </SettingBlock>
        <SettingBlock title={t('title_popular_categories')} icon={<UserIcon />}>
          <FormItem name={GeneralSettingsFormFields.POPULAR_CATEGORIES}>
            <Select
              dropdownStyle={{ display: 'none' }}
              mode="tags"
              suffixIcon={null}
              maxCount={8}
              placeholder={t('placeholder_popular_categories')}
            />
          </FormItem>
        </SettingBlock>
        <SettingBlock title={t('title_home_page_blog_post')} icon={<UserIcon />}>
          <FormItem name={GeneralSettingsFormFields.HOME_PAGE_BLOG_POST}>
            <Input placeholder={t('placeholder_home_page_blog_post')} />
          </FormItem>
        </SettingBlock>
        <SettingBlock title={t('title_phones')} icon={<PhoneIcon />}>
          <FormItem
            name={GeneralSettingsFormFields.PHONES_FOOTER}
            rules={rules[GeneralSettingsFormFields.PHONES_FOOTER]}
            label={t('label_phones_footer')}
          >
            <Input
              ref={(ref) => registerInputRef(t('label_phones_footer'), ref, VariableType.INPUT)}
              onChange={(e) => handleInputChange(t('label_phones_footer'), e.target.value)}
            />
          </FormItem>
          <FormItem
            name={GeneralSettingsFormFields.PHONES_CONTACT_US}
            rules={rules[GeneralSettingsFormFields.PHONES_CONTACT_US]}
            label={t('label_phones_contact')}
          >
            <Input
              ref={(ref) => registerInputRef(t('label_phones_contact'), ref, VariableType.INPUT)}
              onChange={(e) => handleInputChange(t('label_phones_contact'), e.target.value)}
            />
          </FormItem>
        </SettingBlock>
        <SettingBlock title={t('title_contact_info')} icon={<PhoneIcon />}>
          <FormItem
            name={GeneralSettingsFormFields.CONTACT_INFO_ADDRESS}
            rules={rules[GeneralSettingsFormFields.CONTACT_INFO_ADDRESS]}
            label={t('label_contact_info_address')}
          >
            <TextArea
              ref={(ref) => registerInputRef(t('label_contact_info_address'), ref, VariableType.TEXT_AREA)}
              onChange={(e) => handleInputChange(t('label_contact_info_address'), e.target.value)}
              rows={3}
            />
          </FormItem>
          <FormItem
            name={GeneralSettingsFormFields.CONTACT_INFO_EMAIL}
            rules={rules[GeneralSettingsFormFields.CONTACT_INFO_EMAIL]}
            label={t('label_contact_info_email')}
          >
            <Input
              ref={(ref) => registerInputRef(t('label_contact_info_email'), ref, VariableType.INPUT)}
              onChange={(e) => handleInputChange(t('label_contact_info_email'), e.target.value)}
            />
          </FormItem>
          <FormItem
            name={GeneralSettingsFormFields.CONTACT_INFO_WORKING_HOURS}
            rules={rules[GeneralSettingsFormFields.CONTACT_INFO_WORKING_HOURS]}
            label={t('label_contact_info_working_hours')}
          >
            <Input
              ref={(ref) => registerInputRef(t('label_contact_info_working_hours'), ref, VariableType.INPUT)}
              onChange={(e) => handleInputChange(t('label_contact_info_working_hours'), e.target.value)}
            />
          </FormItem>
        </SettingBlock>
        <SettingBlock title={t('title_support_info')} icon={<SupportIcon />}>
          <FormItem name={GeneralSettingsFormFields.SUPPORT_INFO_NAME} label={t('label_support_info_name')}>
            <Input
              ref={(ref) => registerInputRef(t('label_support_info_name'), ref, VariableType.INPUT)}
              onChange={(e) => handleInputChange(t('label_support_info_name'), e.target.value)}
            />
          </FormItem>
          <FormItem
            name={GeneralSettingsFormFields.SUPPORT_INFO_EMAIL}
            rules={rules[GeneralSettingsFormFields.SUPPORT_INFO_EMAIL]}
            label={t('label_support_info_email')}
          >
            <Input
              ref={(ref) => registerInputRef(t('label_support_info_email'), ref, VariableType.INPUT)}
              onChange={(e) => handleInputChange(t('label_support_info_email'), e.target.value)}
            />
          </FormItem>
          <FormItem
            name={GeneralSettingsFormFields.SUPPORT_INFO_PHONE}
            rules={rules[GeneralSettingsFormFields.SUPPORT_INFO_PHONE]}
            label={t('label_support_info_phone')}
          >
            <Input
              ref={(ref) => registerInputRef(t('label_support_info_phone'), ref, VariableType.INPUT)}
              onChange={(e) => handleInputChange(t('label_support_info_phone'), e.target.value)}
            />
          </FormItem>
        </SettingBlock>
      </StyledForm>
    </>
  );
};

export default GeneralSettingsForm;
