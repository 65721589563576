import { BrandsAPI } from '@/api';
import { IGetBrandsDTO, IGetBrandsResponse, IUploadBrandLogoDTO } from '@/models/brands.model';
import { IApiError } from '@/models/apiError.model';
import { formatApiError } from '@/utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { SLICE_NAME } from './models';

export const getBrands = createAsyncThunk<IGetBrandsResponse, IGetBrandsDTO, { serializedErrorType: IApiError }>(
  `${SLICE_NAME}/getBrands`,
  async (data) => {
    const response = await BrandsAPI.getBrands(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const uploadBrandLogo = createAsyncThunk<
  IGetBrandsResponse,
  IUploadBrandLogoDTO,
  { serializedErrorType: IApiError }
>(
  `${SLICE_NAME}/uploadBrandLogo`,
  async (data) => {
    const response = await BrandsAPI.uploadBrandLogo(data);
    return response.data;
  },
  { serializeError: formatApiError },
);
