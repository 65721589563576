// Globals
import { useTranslation } from 'react-i18next';

// Components
import { Form } from '@/components';
import { CheckIcon } from '@/icons';
import { useVariableDetector, VariableDetector } from '@/ui';
import { StyledButton, StyledButtons } from './BlogPostForm.styles';
import PostContent from './PostContent';
import PostSeo from './PostSeo';
import Tabs from 'src/shared/ui/Tabs/Tabs';

// Modules
import { OpenVariablesModuleButton } from '@/modules/Variables';

// Helpers
import { BlogPostFormType, TCreateBlogPostFormValues, TUpdateBlogPostFormValues } from '../../helpers/types';

type TBlogPostFormProps = {
  onSubmit: (values: TCreateBlogPostFormValues) => void;
  initialValues?: Partial<TUpdateBlogPostFormValues>;
  formType: BlogPostFormType;
  isLoading?: boolean;
};

const BlogPostForm = ({ onSubmit, initialValues, formType, isLoading }: TBlogPostFormProps) => {
  const { t } = useTranslation(['common']);
  const { variables, selectVariableInInput, registerInputRef, handleInputChange } = useVariableDetector();

  return (
    <>
      <VariableDetector variables={variables} onSelectVariable={selectVariableInInput} />
      <Form onFinish={onSubmit} initialValues={initialValues}>
        <Tabs
          defaultActiveKey={'content'}
          items={[
            {
              label: t('content'),
              key: 'content',
              children: (
                <PostContent
                  formType={formType}
                  registerInputRef={registerInputRef}
                  handleInputChange={handleInputChange}
                />
              ),
            },
            {
              label: t('menu_seo'),
              key: 'seo',
              children: <PostSeo />,
            },
          ]}
        />

        <StyledButtons>
          <OpenVariablesModuleButton />
          <StyledButton
            type="primary"
            htmlType="submit"
            icon={formType === BlogPostFormType.UPDATE && <CheckIcon />}
            loading={isLoading}
          >
            {t(formType === BlogPostFormType.CREATE ? 'btn_create_blog_post' : 'btn_save')}
          </StyledButton>
        </StyledButtons>
      </Form>
    </>
  );
};

export default BlogPostForm;
