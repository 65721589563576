import { IPaginationDTO, IPaginationResponse } from '@/models/pagination.model';

export enum BrandsEndpoints {
  ROOT = '/brands',
}

export const staticBrandsEndpoints = {
  GET_BRANDS: BrandsEndpoints.ROOT,
};

export const getBrandsEndpoints = ({ id }: { id: string }) => ({
  UPDATE_BRAND: `${BrandsEndpoints.ROOT}/${id}`,
});

export type IBrand = {
  id: string;
  code: string;
  files?: Array<{ file: { url: string } }>;
  labels: { en_US?: string };
  sortOrder: number;
  createdAt: string;
  updatedAt: string;
};

// ============== DTO ==============

export interface IGetBrandsDTO extends IPaginationDTO {
  channelId: number;
  search?: string;
}

export interface IUploadBrandLogoDTO {
  channelId: number;
  id: string;
  logo: File;
}

// ============== Response ==============

export interface IGetBrandsResponse extends IPaginationResponse {
  brands: IBrand[];
}
