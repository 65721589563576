// Globals
import { useTranslation } from 'react-i18next';

// Modules
import { selectChannels } from '@/modules/Channels';
import { Header } from '@/modules/Layout';

// Store
import { useAppDispatch, useAppState } from '@/store';
import { getEntities, syncByEntity, syncEntity } from '../../feature';

// Components
import { FormItem, showSuccessToast, useForm } from '@/components';
import { Button, Select, Spinner } from '@/ui';
import { ProductSyncForm } from '../../components';
import { StyledContainer, StyledSyncForm, StyledTable, StyledTitle } from './AkeneoSyncContainerStyles';

// Models
import { EntityTypes } from '@/models/akeneoSync.model';

// Helpers
import { getEntitiesOptions, getSyncTableConfig, SyncFormFormFields, TSyncFormValues } from '../../helpers';
import { useGetEntities, useSyncFormRules } from '../../hooks';

const AkeneoSyncContainer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const channels = useAppState(selectChannels);

  const { entities, isLoading } = useGetEntities();

  const entitiesOptions = getEntitiesOptions(t);
  const tableConfig = getSyncTableConfig(t);
  const rules = useSyncFormRules();

  const [productSyncForm] = useForm();
  const [entitySyncForm] = useForm();

  const handleSubmit = async (values: TSyncFormValues) => {
    try {
      await dispatch(syncByEntity(values)).unwrap();
      productSyncForm.resetFields();
      showSuccessToast({
        message: t('message_sync_success'),
      });
    } catch {}
  };

  const handleSubmitSyncEntity = async (values: TSyncFormValues) => {
    try {
      await dispatch(
        syncEntity({
          entityTypes: values[SyncFormFormFields.TYPE] as unknown as EntityTypes[],
        }),
      ).unwrap();
      dispatch(getEntities());
      entitySyncForm.resetFields();
      showSuccessToast({
        message: t('message_sync_success'),
      });
    } catch {}
  };

  return (
    <>
      <Header title={t('menu_akeneo')} />
      <Spinner spinning={isLoading}>
        <StyledContainer>
          <ProductSyncForm onSubmit={handleSubmit} productSyncForm={productSyncForm} channels={channels ?? []} />
          <div>
            <div>
              <StyledTitle>{t('label_products_sync')}</StyledTitle>
              <StyledSyncForm onFinish={handleSubmitSyncEntity} layout="inline" form={entitySyncForm}>
                <FormItem name={SyncFormFormFields.TYPE} rules={rules[SyncFormFormFields.TYPE]}>
                  <Select isMultiselect options={entitiesOptions} />
                </FormItem>
                <Button block type="primary" htmlType="submit">
                  {t('button_sync_products')}
                </Button>
              </StyledSyncForm>
            </div>
            <StyledTable
              columns={tableConfig}
              dataSource={entities ?? []}
              rowKey="id"
              pagination={false}
              loading={isLoading}
            />
          </div>
        </StyledContainer>
      </Spinner>
    </>
  );
};

export default AkeneoSyncContainer;
