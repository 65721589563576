import { ICreateMetatagsDTO } from '@/models/metatags.model';
import { MetatagsFormFields, TCreateMetatagsTemplateFormValues } from '../helpers';

class CreateMetatagDTO implements ICreateMetatagsDTO {
  link: string;

  title: string;

  description: string;

  keywords?: string[];

  robots: string;

  author: string;

  ogTitle: string;

  ogDescription: string;

  ogImage: string;

  ogUrl: string;

  channelId: number;

  constructor(values: TCreateMetatagsTemplateFormValues & { channelId: number }) {
    this.link = decodeURIComponent(decodeURIComponent(values[MetatagsFormFields.LINK]));
    this.title = values[MetatagsFormFields.TITLE];
    this.description = values[MetatagsFormFields.DESCRIPTION];
    this.keywords = values[MetatagsFormFields.KEYWORDS];
    this.robots = values[MetatagsFormFields.ROBOTS];
    this.author = values[MetatagsFormFields.AUTHOR];
    this.ogTitle = values[MetatagsFormFields.OG_TILE];
    this.ogDescription = values[MetatagsFormFields.OG_DESCRIPTION];
    this.ogImage = values[MetatagsFormFields.OG_IMAGE];
    this.ogUrl = values[MetatagsFormFields.OG_URL];
    this.channelId = values.channelId;
  }
}

export default CreateMetatagDTO;
