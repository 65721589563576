// Globals
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Modules
import { useGetCategories } from '@/modules/Categories';
import { selectChannel } from '@/modules/Channels';
import { Header } from '@/modules/Layout';

// Models
import { IFaq } from '@/models/faq.model';

// Components
import { Modal } from '@/components';
import { NotFoundIcon } from '@/icons';
import { Button, Pagination, Table } from '@/ui';
import { FaqForm, StyledButtons } from '../../components';
import { StyledContainer } from './FaqsContainerStyles';

// Store
import { useAppDispatch, useAppState } from '@/store';
import { createFaq, deleteFaq, getFaqs, updateFaq } from '../../feature';

// Helpers
import { useBoolean } from '@/hooks';
import { getTableConfig, mapToFormValues, TFaqFormValues } from '../../helpers';
import { useGetFaqs } from '../../hooks';

const FaqsContainer = () => {
  const { t } = useTranslation();
  const { isLoading, faqs, pagination } = useGetFaqs();
  const { categories } = useGetCategories();
  const currentChanel = useAppState(selectChannel);
  const dispatch = useAppDispatch();
  const { value: isOpen, toggle: toggleOpen } = useBoolean();
  const [deletedFaq, setDeletedFaq] = useState<IFaq | null>(null);
  const [editableFaq, setEditableFaq] = useState<IFaq | null>(null);
  const handleDelete = async () => {
    if (!deletedFaq?.id) return;
    try {
      await dispatch(
        deleteFaq({
          id: deletedFaq?.id,
        }),
      ).unwrap();
      setDeletedFaq(null);
    } catch (error) {}
  };

  const handleCancelDelete = () => setDeletedFaq(null);

  const handleCreate = () => {
    toggleOpen();
    setEditableFaq(null);
  };

  const handleSubmit = async (values: TFaqFormValues) => {
    if (!currentChanel?.id) return;
    try {
      if (editableFaq) {
        await dispatch(updateFaq({ ...values, id: editableFaq.id, isActive: !!values.isActive })).unwrap();
      } else {
        await dispatch(createFaq({ ...values, channelId: currentChanel.id, isActive: !!values.isActive })).unwrap();
      }

      await dispatch(
        getFaqs({
          channelId: currentChanel?.id,
          page: pagination?.page || 1,
        }),
      );
      toggleOpen();
      setEditableFaq(null);
    } catch {}
  };

  const handelEdit = (faq: IFaq) => {
    setEditableFaq(faq);
    toggleOpen();
  };

  const handlePageChange = (page: number) => {
    if (!currentChanel?.id) return;
    dispatch(
      getFaqs({
        channelId: currentChanel?.id,
        page,
      }),
    );
  };

  const config = getTableConfig(t, setDeletedFaq, handelEdit);

  return (
    <>
      <Header title={t('menu_faqs')}>
        <Button type="primary" icon={<NotFoundIcon />} onClick={handleCreate}>
          {t('create_btn')}
        </Button>
      </Header>
      <StyledContainer>
        <Table
          loading={isLoading}
          columns={config}
          rowKey="id"
          dataSource={faqs ?? []}
          pagination={false}
          scroll={{ x: 'max-content' }}
          size="small"
        />
        <Pagination
          hideOnSinglePage
          total={pagination?.total}
          current={pagination?.page}
          pageSize={pagination?.pageSize}
          align="center"
          onChange={handlePageChange}
        />
      </StyledContainer>
      <FaqForm
        isOpen={isOpen}
        toggleOpen={handleCreate}
        category={categories || undefined}
        isLoading={isLoading}
        onSubmit={handleSubmit}
        isEdit={!!editableFaq}
        initialValues={editableFaq ? mapToFormValues(editableFaq) : undefined}
      />
      <Modal
        visible={!!deletedFaq}
        onOk={handleDelete}
        onCancel={handleCancelDelete}
        withFooter
        title={t('delete_faq_confirm')}
        footer={
          <StyledButtons>
            <Button block onClick={handleCancelDelete}>
              {t('cancel_btn')}
            </Button>
            <Button block type="primary" danger onClick={handleDelete}>
              {t('confirm')}
            </Button>
          </StyledButtons>
        }
      />
    </>
  );
};

export default FaqsContainer;
