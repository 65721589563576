// Globals
import { useTranslation } from 'react-i18next';

// Components
import { Form, FormItem } from '@/components';
import { Button, Input, Select } from '@/ui';
import { StyledButtons, StyledLinkInputWrapper, StyledWrapper } from './MetatagsFormStyles';
import { CheckIcon } from '@/icons';
import { StyledTruncate } from '../Styled';

// Helpers
import {
  MetatagsFormFields,
  TCreateMetatagsTemplateFormValues,
  TUpdateMetatagsTemplateFormValues,
} from '../../helpers';
import { useMetatagsFormRules } from '../../hooks';
import { trimEnd } from 'lodash';

type TMetatagsFormProps = {
  onSubmit: (values: TCreateMetatagsTemplateFormValues) => void;
  initialValues?: Partial<TUpdateMetatagsTemplateFormValues>;
  siteURL?: string;
  isLoading?: boolean;
};

const MetatagsForm = ({ onSubmit, initialValues, siteURL, isLoading }: TMetatagsFormProps) => {
  const isRequired =
    initialValues?.[MetatagsFormFields.LINK] !== 'product' && initialValues?.[MetatagsFormFields.LINK] !== 'category';
  const rules = useMetatagsFormRules(isRequired);
  const { t } = useTranslation(['common']);

  return (
    <Form onFinish={onSubmit} initialValues={initialValues}>
      {siteURL && (
        <StyledLinkInputWrapper>
          <FormItem
            name={MetatagsFormFields.LINK}
            label={t('label_link')}
            rules={rules[MetatagsFormFields.LINK]}
            extra={
              <StyledWrapper>
                <StyledTruncate $width="25rem">{trimEnd(siteURL, '/')}</StyledTruncate>
                <h3>/</h3>
              </StyledWrapper>
            }
          >
            <Input />
          </FormItem>
        </StyledLinkInputWrapper>
      )}
      <FormItem
        name={MetatagsFormFields.TITLE}
        label={t('update_page_form_seo_title')}
        rules={rules[MetatagsFormFields.TITLE]}
      >
        <Input />
      </FormItem>
      <FormItem
        name={MetatagsFormFields.DESCRIPTION}
        label={t('update_page_form_seo_description')}
        rules={rules[MetatagsFormFields.DESCRIPTION]}
      >
        <Input />
      </FormItem>
      <FormItem
        name={MetatagsFormFields.KEYWORDS}
        label={t('update_page_form_seo_keywords')}
        rules={rules[MetatagsFormFields.KEYWORDS]}
      >
        <Select dropdownStyle={{ display: 'none' }} mode="tags" suffixIcon={null} />
      </FormItem>
      <FormItem
        name={MetatagsFormFields.ROBOTS}
        label={t('update_page_form_seo_robots')}
        rules={rules[MetatagsFormFields.LINK]}
      >
        <Input />
      </FormItem>
      <FormItem
        name={MetatagsFormFields.AUTHOR}
        label={t('update_page_form_seo_author')}
        rules={rules[MetatagsFormFields.LINK]}
      >
        <Input />
      </FormItem>
      <FormItem
        name={MetatagsFormFields.OG_TILE}
        label={t('update_page_form_seo_ogTitle')}
        rules={rules[MetatagsFormFields.TITLE]}
      >
        <Input />
      </FormItem>
      <FormItem
        name={MetatagsFormFields.OG_DESCRIPTION}
        label={t('update_page_form_seo_ogDescription')}
        rules={rules[MetatagsFormFields.DESCRIPTION]}
      >
        <Input />
      </FormItem>
      <FormItem
        name={MetatagsFormFields.OG_IMAGE}
        label={t('update_page_form_seo_ogImage')}
        rules={rules[MetatagsFormFields.LINK]}
      >
        <Input />
      </FormItem>
      <FormItem
        name={MetatagsFormFields.OG_URL}
        label={t('update_page_form_seo_ogUrl')}
        rules={rules[MetatagsFormFields.LINK]}
      >
        <Input />
      </FormItem>
      <StyledButtons>
        <Button type="primary" htmlType="submit" icon={!siteURL && <CheckIcon />} loading={isLoading}>
          {t(siteURL ? 'btn_create_metatag' : 'btn_save')}
        </Button>
      </StyledButtons>
    </Form>
  );
};

export default MetatagsForm;
