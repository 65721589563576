// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { FormItem } from '@/components';
import { DatePicker, Input, Select, Spinner, TextArea, TextEditor, VariableType } from '@/ui';

// Models
import { BlogPostStatus } from '@/models/blogPost.model';

// Modules
import { ChannelsSelect } from '@/modules/Pages';
import { WideFormItem } from '@/modules/Reviews/components/CreateReviewForm';
import { ImageUpload } from '@/modules/Settings';

// Helpers
import { BlogPostFormFields, BlogPostFormType } from '../../helpers/types';
import { useSearchArticles } from '../../hooks';
import useBlogPostFormRules from '../../hooks/useBlogPostFormRules';

type TPostContent = {
  formType: BlogPostFormType;
  registerInputRef: (inputName: string, ref: any, type: VariableType) => void;
  handleInputChange: (...args: any[]) => void;
};

const PostContent = ({ formType, registerInputRef, handleInputChange }: TPostContent) => {
  const rules = useBlogPostFormRules();
  const { t } = useTranslation(['common']);
  const { searchArticles, loading, options } = useSearchArticles();

  const statusOptions = [
    { value: BlogPostStatus.DRAFT, label: String(BlogPostStatus.DRAFT) },
    { value: BlogPostStatus.SCHEDULED, label: String(BlogPostStatus.SCHEDULED) },
    { value: BlogPostStatus.PUBLISHED, label: String(BlogPostStatus.PUBLISHED) },
  ];

  return (
    <>
      <FormItem
        name={BlogPostFormFields.BLOG_POST_CHANNELS}
        label={t('label_channel')}
        rules={rules[BlogPostFormFields.BLOG_POST_CHANNELS]}
      >
        <ChannelsSelect filterCurrentChannel={false} placeholder={t('placeholder_channels')} />
      </FormItem>

      <FormItem name={BlogPostFormFields.SLUG} label={t('label_slug')} rules={rules[BlogPostFormFields.SLUG]}>
        <Input disabled={formType === BlogPostFormType.UPDATE} placeholder={t('placeholder_blog_post_slug')} />
      </FormItem>

      <FormItem name={BlogPostFormFields.STATUS} label={t('label_status')} rules={rules[BlogPostFormFields.STATUS]}>
        <Select options={statusOptions} placeholder={t('placeholder_status')} />
      </FormItem>

      <FormItem
        name={BlogPostFormFields.PUBLISHED_AT}
        label={t('label_published_at')}
        rules={rules[BlogPostFormFields.PUBLISHED_AT]}
      >
        <DatePicker showTime placeholder={t('placeholder_published_at')} />
      </FormItem>

      <FormItem name={BlogPostFormFields.TITLE} label={t('label_title')} rules={rules[BlogPostFormFields.TITLE]}>
        <Input
          ref={(ref) => registerInputRef(t('label_title'), ref, VariableType.INPUT)}
          onChange={(e) => handleInputChange(t('label_title'), e.target.value)}
          placeholder={t('placeholder_blog_post_title')}
        />
      </FormItem>

      <FormItem>
        <WideFormItem label={t('label_banner')} name={BlogPostFormFields.BANNER}>
          <ImageUpload recommendedSize={{ width: 992, height: 432 }} />
        </WideFormItem>
      </FormItem>

      <FormItem
        name={BlogPostFormFields.SHORT_DESCRIPTION}
        label={t('label_short_description')}
        rules={rules[BlogPostFormFields.SHORT_DESCRIPTION]}
      >
        <TextArea
          ref={(ref) => registerInputRef(t('label_short_description'), ref, VariableType.TEXT_AREA)}
          onChange={(e) => handleInputChange(t('label_short_description'), e.target.value)}
          placeholder={t('placeholder_short_description')}
          autoSize={{ minRows: 4, maxRows: 10 }}
        />
      </FormItem>

      <FormItem name={BlogPostFormFields.SIMILAR_BLOG_POSTS} label={t('similar_blog_posts')}>
        <Select
          mode="multiple"
          showSearch
          onSearch={searchArticles}
          placeholder={t('placeholder_similar_blog_posts')}
          options={options}
          filterOption={false}
          notFoundContent={null}
          loading={loading}
          suffixIcon={loading ? <Spinner size="small" spinning={loading} /> : null}
        />
      </FormItem>

      <FormItem name={BlogPostFormFields.CONTENT} label={t('label_content')} rules={rules[BlogPostFormFields.CONTENT]}>
        <TextEditor
          ref={(ref) => registerInputRef(t('label_content'), ref, VariableType.TEXT_EDITOR)}
          onChange={(_, plainText) => handleInputChange(t('label_content'), plainText)}
          placeholder={t('placeholder_content')}
          enableImageUpload
        />
      </FormItem>
    </>
  );
};

export default PostContent;
