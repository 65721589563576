import { IUpdateMetatagsDTO } from '@/models/metatags.model';
import { MetatagsFormFields, TUpdateMetatagsTemplateFormValues } from '../helpers';

class UpdateMetatagDTO implements IUpdateMetatagsDTO {
  title: string;

  description: string;

  keywords?: string[];

  robots: string;

  author: string;

  ogTitle: string;

  ogDescription: string;

  ogImage: string;

  ogUrl: string;

  id: string;

  constructor(values: TUpdateMetatagsTemplateFormValues & { id: string }) {
    this.title = values[MetatagsFormFields.TITLE];
    this.description = values[MetatagsFormFields.DESCRIPTION];
    this.keywords = values[MetatagsFormFields.KEYWORDS];
    this.robots = values[MetatagsFormFields.ROBOTS];
    this.author = values[MetatagsFormFields.AUTHOR];
    this.ogTitle = values[MetatagsFormFields.OG_TILE];
    this.ogDescription = values[MetatagsFormFields.OG_DESCRIPTION];
    this.ogImage = values[MetatagsFormFields.OG_IMAGE];
    this.ogUrl = values[MetatagsFormFields.OG_URL];
    this.id = values.id;
  }
}

export default UpdateMetatagDTO;
