import { IGetRedirectsDTO } from '@/models/redirect.model';

export class GetRedirectsDTO implements IGetRedirectsDTO {
  pageSize?: string;

  page?: string;

  channelId: number;

  search?: string;

  constructor({
    pageSize,
    page,
    channelId,
    query,
  }: {
    pageSize?: number;
    page?: string | number;
    channelId: number;
    query?: string;
  }) {
    this.pageSize = String(pageSize || 50);
    this.page = typeof page === 'number' ? String(page) : (page ?? '1');
    this.channelId = channelId;
    this.search = query || undefined;
  }
}
