import { IAdvantage } from '@/models/category.model';

export enum UpdateCategoryFieldNames {
  IMAGE = 'image',
  THUMBNAIL = 'thumbnail',
  VIDEO = 'video',
  ICON = 'icon',
  ADVANTAGES = 'advantages',
  TITLE = 'title',
  DESCRIPTION = 'description',
  SEO_BLOCK = 'seoBlocks',
  SEO_KEYWORDS = 'seoKeywords',
  SEO_DESCRIPTION = 'seoDescription',
  SEO_TITLE = 'seoTitle',
  SEO_ROBOTS = 'seoRobots',
  SEO_AUTHOR = 'seoAuthor',
  SEO_OG_TILE = 'seoOgTitle',
  SEO_OG_DESCRIPTION = 'seoOgDescription',
  SEO_OG_IMAGE = 'seoOgImage',
  SEO_OG_URL = 'seoOgUrl',
}

export type TUpdateCategoryFormValues = {
  [UpdateCategoryFieldNames.IMAGE]?: Blob | string;
  [UpdateCategoryFieldNames.VIDEO]?: Blob | string;
  [UpdateCategoryFieldNames.ICON]?: Blob | string;
  [UpdateCategoryFieldNames.THUMBNAIL]?: Blob | string;
  [UpdateCategoryFieldNames.ADVANTAGES]?: IAdvantage[];
  [UpdateCategoryFieldNames.SEO_KEYWORDS]?: string[];
  [UpdateCategoryFieldNames.SEO_DESCRIPTION]?: string;
  [UpdateCategoryFieldNames.SEO_TITLE]?: string;
  [UpdateCategoryFieldNames.SEO_ROBOTS]?: string;
  [UpdateCategoryFieldNames.SEO_AUTHOR]?: string;
  [UpdateCategoryFieldNames.SEO_OG_TILE]?: string;
  [UpdateCategoryFieldNames.SEO_OG_DESCRIPTION]?: string;
  [UpdateCategoryFieldNames.SEO_OG_IMAGE]?: string;
  [UpdateCategoryFieldNames.SEO_OG_URL]?: string;
  [UpdateCategoryFieldNames.DESCRIPTION]?: string;
  [UpdateCategoryFieldNames.SEO_BLOCK]?: string[];
};
