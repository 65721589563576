import { Form } from '@/components';
import { pickThemeFontStyles } from '@/theme';
import { DatePicker } from '@/ui';
import { styled } from 'styled-components';

export const StyledTitle = styled.h2`
  ${pickThemeFontStyles('24', '32', 'BOLD')};
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  max-width: 35rem;
`;

export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`;

export const StyledSyncForm = styled(Form)`
  max-width: 35rem;
  display: grid;
  grid-template-columns: 1fr auto;
  margin-bottom: 1rem;
`;
