// Globals
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Modules
import { selectChannel } from '@/modules/Channels';
import { Header } from '@/modules/Layout';
import { ImageUpload } from '@/modules/Settings';

// Store
import { useAppDispatch, useAppState } from '@/store';
import { getBrands, uploadBrandLogo } from '../../feature';

// Components
import { Modal, ModalSize, showSuccessToast } from '@/components';
import { Pagination, Table } from '@/ui';
import { StyledContainer } from './BrandsContainerStyles';

// Helpers
import { GetBrandsDTO } from '../../dtos';
import { getBrandsTableConfig } from '../../helpers';
import { useGetBrands } from '../../hooks';
import { debounce } from 'lodash';
import { StyledLogo } from '../../components/Styled';

const BrandsContainer = () => {
  const { t } = useTranslation();
  const [selectedBrandId, setSelectedBrandId] = useState<string | null>(null);

  const dispatch = useAppDispatch();
  const channel = useAppState(selectChannel);
  const { brands, isLoading, pagination } = useGetBrands();

  const handlePageChange = (page: number) => {
    if (!channel) return;
    const dto = new GetBrandsDTO({ page, channelId: channel.id });
    dispatch(getBrands(dto));
  };

  const handleClose = () => setSelectedBrandId(null);

  const handleUpload = async (file?: File | null | Blob) => {
    if (!selectedBrandId || !channel || !file) return;
    try {
      await dispatch(
        uploadBrandLogo({
          id: selectedBrandId,
          channelId: channel.id,
          logo: file as File,
        }),
      ).unwrap();

      setSelectedBrandId(null);
      showSuccessToast({
        message: t('brand_image_updated'),
      });
      const dto = new GetBrandsDTO({ page: 1, channelId: channel.id });
      await dispatch(getBrands(dto));
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearch = debounce((event: ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.trim();
    if (!channel) return;
    const dto = new GetBrandsDTO({ page: 1, channelId: channel.id, search: query || undefined });
    dispatch(getBrands(dto));
  }, 500);

  const tableConfig = getBrandsTableConfig(t, handleSearch);

  const selectedBrand = brands?.find((brand) => brand.id === selectedBrandId);
  const selectedBrandImageUrl = selectedBrand?.files?.[0]?.file?.url;

  return (
    <>
      <Header title={t('menu_brands')} />
      <StyledContainer>
        <Table
          loading={isLoading}
          dataSource={brands ?? []}
          rowKey="id"
          onRow={(record) => ({
            onClick: () => setSelectedBrandId(record.id),
          })}
          columns={tableConfig}
          pagination={false}
          scroll={{ x: 'max-content' }}
        />
        <Pagination
          hideOnSinglePage
          total={pagination?.total}
          current={pagination?.page}
          pageSize={pagination?.pageSize}
          align="center"
          onChange={handlePageChange}
        />
        <Modal
          visible={!!selectedBrandId}
          onCancel={handleClose}
          footer={null}
          title={t('brand_image')}
          size={ModalSize.LARGE}
        >
          {selectedBrandImageUrl ? (
            <StyledLogo>
              <img src={selectedBrandImageUrl} alt="logo" />
            </StyledLogo>
          ) : null}
          <ImageUpload accept="image/*" onChange={handleUpload} />
        </Modal>
      </StyledContainer>
    </>
  );
};

export default BrandsContainer;
