import { IGetBrandsDTO } from '@/models/brands.model';
import { PaginationFields } from '@/models/pagination.model';

class GetBrandsDTO implements IGetBrandsDTO {
  channelId: number;

  search?: string;

  [PaginationFields.PAGE]?: string | number;

  [PaginationFields.PAGE_SIZE]?: string | number;

  constructor({ channelId, search, page, pageSize }: IGetBrandsDTO) {
    this.channelId = channelId;
    this.search = search;
    this[PaginationFields.PAGE] = page || 1;
    this[PaginationFields.PAGE_SIZE] = pageSize || 10;
  }
}

export default GetBrandsDTO;
