import { useEffect, useMemo } from 'react';
import { getEntities, selectEntities, selectError, selectIsLoading } from '../feature';
import { useAppDispatch, useAppState } from '@/store';

export const useGetEntities = () => {
  const dispatch = useAppDispatch();
  const entities = useAppState(selectEntities);
  const isLoading = useAppState(selectIsLoading);
  const error = useAppState(selectError);

  useEffect(() => {
    dispatch(getEntities());
  }, [dispatch]);

  return useMemo(() => ({ entities, isLoading, error }), [entities, isLoading, error]);
};
