import { Modal, ModalSize } from '@/components';
import { PlusIcon, TrashIcon } from '@/icons';
import { IVariable } from '@/models/variable.model';
import { Header } from '@/modules/Layout';
import { useAppDispatch } from '@/store';
import { Button, Table } from '@/ui';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HowItWorks } from '../../components/HowItWorks';
import VariableForm from '../../components/VariableForm/VariableForm';
import { VariableSearch } from '../../components/VariableSearch';
import { VariableUsagesList } from '../../components/VariableUsagesList/VariableUsagesList';
import CreateVariableDTO from '../../dtos/CreateVariable.dto';
import { GetVariablesDTO } from '../../dtos/GetVariables.dto';
import UpdateVariableDTO from '../../dtos/UpdateVariable.dto';
import { createVariable, deleteVariable, getVariables, updateVariable } from '../../feature';
import {
  TCreateVariableFormValues,
  TUpdateVariableFormValues,
  VariableFormFields,
  VariableFormType,
} from '../../helpers/types';
import { variablesColumns } from '../../helpers/variablesColumns';
import { useVariables } from '../../hooks/useVariables';
import { StyledButtons, StyledContainer, StyledMessage, StyledPagination } from './VariablesContainer.styles';
import { useSearchParams } from 'react-router-dom';

export const VariablesContainer: FC = () => {
  const { t } = useTranslation(['common']);
  const { variables, isLoading, currentChannel, pagination } = useVariables();
  const [query] = useSearchParams();
  const dispatch = useAppDispatch();

  const [selectedVariable, setSelectedVariable] = useState<IVariable | null>(null);
  const [formType, setFormType] = useState<VariableFormType | null>(null);

  const handleDelete = (record: IVariable) => {
    setSelectedVariable(record);
    setFormType(VariableFormType.DELETE);
  };

  const handleOpenEdit = (record: IVariable) => {
    setSelectedVariable(record);
    setFormType(VariableFormType.UPDATE);
  };

  const handlePageChange = (page: number) => {
    if (!currentChannel?.id) return;
    const dto = new GetVariablesDTO({ page, channelId: currentChannel.id, query: query.get('search') || undefined });
    dispatch(getVariables(dto)).unwrap();
  };

  const handleOpenCreate = () => {
    setSelectedVariable(null);
    setFormType(VariableFormType.CREATE);
  };

  const handleCloseModal = () => {
    setFormType(null);
  };

  const handleSubmitUpdateVariable = useCallback(
    async (values: TUpdateVariableFormValues) => {
      if (!selectedVariable?.id || !currentChannel?.id) return;
      const updateDto = new UpdateVariableDTO({ ...values, id: selectedVariable?.id, channelId: currentChannel.id });
      try {
        await dispatch(updateVariable(updateDto)).unwrap();
        setSelectedVariable(null);
        setFormType(null);
      } catch {}
    },
    [currentChannel?.id, dispatch, selectedVariable?.id],
  );

  const handleConfirmDelete = useCallback(async () => {
    if (!selectedVariable) return;
    try {
      await dispatch(deleteVariable({ id: selectedVariable.id })).unwrap();
    } catch (error) {}

    setSelectedVariable(null);
    setFormType(null);
  }, [dispatch, selectedVariable]);

  const handleSubmitCreateVariable = useCallback(
    async (values: TCreateVariableFormValues) => {
      setSelectedVariable(null);
      if (!currentChannel?.id) return;
      const createDto = new CreateVariableDTO({ ...values, channelId: currentChannel.id });
      const getVariablesDto = new GetVariablesDTO({ page: 1, channelId: currentChannel.id });
      try {
        await dispatch(createVariable(createDto)).unwrap();
        if (query.get('page') !== '1') {
          dispatch(getVariables(getVariablesDto)).unwrap();
        }
        setFormType(null);
      } catch {}
    },
    [currentChannel?.id, dispatch, query],
  );

  const getModalContent = useCallback(
    (type: VariableFormType) => {
      switch (type) {
        case VariableFormType.UPDATE:
          return (
            <VariableForm
              onSubmit={handleSubmitUpdateVariable}
              isLoading={isLoading}
              formType={VariableFormType.UPDATE}
              initialValues={{
                [VariableFormFields.CODE]: selectedVariable?.code,
                [VariableFormFields.DEFAULT_VALUE]: selectedVariable?.defaultValue,
                [VariableFormFields.VALUE]: selectedVariable?.value,
              }}
            />
          );
        case VariableFormType.DELETE:
          return (
            <div>
              <StyledMessage>{t(`delete_variable_message`)}</StyledMessage>
              <StyledButtons>
                <Button
                  type="primary"
                  danger
                  size="small"
                  onClick={handleConfirmDelete}
                  icon={<TrashIcon />}
                  loading={isLoading}
                >
                  {t('delete_variable')}
                </Button>
              </StyledButtons>
            </div>
          );
        case VariableFormType.CREATE:
          return (
            <VariableForm
              formType={VariableFormType.CREATE}
              isLoading={isLoading}
              onSubmit={handleSubmitCreateVariable}
            />
          );
        default:
          return null;
      }
    },
    [
      handleConfirmDelete,
      handleSubmitUpdateVariable,
      handleSubmitCreateVariable,
      isLoading,
      selectedVariable?.code,
      selectedVariable?.defaultValue,
      selectedVariable?.value,
      t,
    ],
  );

  const title = useMemo(() => {
    switch (formType) {
      case VariableFormType.UPDATE:
        return t('title_edit_variable');
      case VariableFormType.DELETE:
        return t('delete_variable_confirm', { code: selectedVariable?.code });
      case VariableFormType.CREATE:
        return t('title_create_variable');
      default:
        return null;
    }
  }, [formType, selectedVariable?.code, t]);

  return (
    <>
      <Header title={t('menu_variables')}>
        <Button icon={<PlusIcon />} type="primary" onClick={handleOpenCreate}>
          {t('btn_add_variable')}
        </Button>
      </Header>
      <StyledContainer>
        <HowItWorks />
        <VariableSearch loading={isLoading} />
        <Table
          columns={variablesColumns(t, handleDelete, handleOpenEdit)}
          rowKey="id"
          dataSource={variables ?? []}
          loading={isLoading}
          pagination={false}
          size="small"
          rowHoverable
          virtual
          expandable={{
            expandedRowRender: (record) => <VariableUsagesList variableUsages={record.usages} />,
            rowExpandable: (record) => record.usages.length > 0,
            columnWidth: 50,
            columnTitle: t('column_usages'),
          }}
        />
        <StyledPagination
          disabled={isLoading}
          total={pagination?.total ?? 0}
          current={pagination?.page ?? 1}
          pageSize={pagination?.pageSize ?? 10}
          onChange={handlePageChange}
          hideOnSinglePage
        />
      </StyledContainer>
      <Modal
        title={title}
        size={formType === VariableFormType.DELETE ? ModalSize.MEDIUM : ModalSize.LARGE}
        visible={!!formType}
        onCancel={handleCloseModal}
        destroyOnClose
      >
        {!!formType && getModalContent(formType)}
      </Modal>
    </>
  );
};
