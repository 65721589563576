import { IChannel } from '@/models/channel.model';
import { IUpdateGeneralSettingsDTO } from '@/models/settings.model';
import { GeneralSettingsFormFields, TGeneralSettingsFormValues } from '../helpers/types';

class UpdateGeneralSettingsDTO implements IUpdateGeneralSettingsDTO {
  adminEmails?: string[];

  copyright?: string;

  monthlySpecialCategory?: string | undefined;

  newMachinesCategory?: string | undefined;

  googleTagManagement?: string;

  channel?: string;

  smtp?: {
    host?: string;
    port?: number;
    auth?: {
      user?: string;
      pass?: string;
    };
  };

  contactInfo?: {
    phone?: string;
    email?: string;
    workingHours?: string;
    address?: string;
  };

  supportInfo?: {
    phone?: string;
    email?: string;
    name?: string;
  };

  phones?: {
    footer?: string;
    contactUsPage?: string;
  };

  uuid: string;

  popularProductIds?: string[];

  topPicksOfTheMonthProductIds?: string[];

  popularCategoriesIds?: string[];

  homePageBlogPostSlug?: string;

  ogSiteName?: string;

  constructor(
    values: Partial<TGeneralSettingsFormValues> & {
      channelConfig: IChannel;
      uuid: string;
    },
  ) {
    this.copyright = values[GeneralSettingsFormFields.COPYRIGHT];
    this.monthlySpecialCategory = values[GeneralSettingsFormFields.MONTHLY_SPECIAL_CATEGORY];
    this.newMachinesCategory = values[GeneralSettingsFormFields.NEW_MACHINES_CATEGORY];
    this.googleTagManagement = values[GeneralSettingsFormFields.GOOGLE_TAG_MANAGER];
    this.adminEmails = values[GeneralSettingsFormFields.ADMIN_EMAILS];
    this.smtp = {
      host: values[GeneralSettingsFormFields.SMTP_HOST],
      port: values[GeneralSettingsFormFields.SMTP_PORT]
        ? Number(values[GeneralSettingsFormFields.SMTP_PORT])
        : undefined,
      auth: {
        user: values[GeneralSettingsFormFields.SMTP_AUTH_USER],
        pass: values[GeneralSettingsFormFields.SMTP_AUTH_PASS],
      },
    };
    this.phones = {
      footer: values[GeneralSettingsFormFields.PHONES_FOOTER]
        ? values[GeneralSettingsFormFields.PHONES_FOOTER]
        : undefined,
      contactUsPage: values[GeneralSettingsFormFields.PHONES_CONTACT_US]
        ? values[GeneralSettingsFormFields.PHONES_CONTACT_US]
        : undefined,
    };
    this.channel = values[GeneralSettingsFormFields.CHANNEL] ?? values.channelConfig.slug;
    this.uuid = values.uuid;
    this.popularProductIds = values[GeneralSettingsFormFields.POPULAR_PRODUCTS];

    this.contactInfo = {
      email: values[GeneralSettingsFormFields.CONTACT_INFO_EMAIL],
      workingHours: values[GeneralSettingsFormFields.CONTACT_INFO_WORKING_HOURS],
      address: values[GeneralSettingsFormFields.CONTACT_INFO_ADDRESS],
    };

    this.supportInfo = {
      email: values[GeneralSettingsFormFields.SUPPORT_INFO_EMAIL],
      phone: values[GeneralSettingsFormFields.SUPPORT_INFO_PHONE],
      name: values[GeneralSettingsFormFields.SUPPORT_INFO_NAME],
    };

    this.popularCategoriesIds = values[GeneralSettingsFormFields.POPULAR_CATEGORIES];
    this.topPicksOfTheMonthProductIds = values[GeneralSettingsFormFields.TOP_PICKS_OF_THE_MONTH];
    this.homePageBlogPostSlug = values[GeneralSettingsFormFields.HOME_PAGE_BLOG_POST];
    this.ogSiteName = values[GeneralSettingsFormFields.META_OG_SITE_NAME];
  }
}

export default UpdateGeneralSettingsDTO;
