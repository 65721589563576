import { IMetaTags } from '@/models/metatags.model';

export const getMetaInitialValues = (meta: IMetaTags) => {
  return {
    description: meta?.description,
    keywords: meta?.keywords,
    title: meta?.title,
    link: meta?.link,
    robots: meta?.robots,
    author: meta?.author,
    ogTitle: meta?.ogTitle,
    ogDescription: meta?.ogDescription,
    ogImage: meta?.ogImage,
    ogUrl: meta?.ogUrl,
  };
};
