import { DateFormats } from '@/constants/dates';
import { IBrand } from '@/models/brands.model';
import { Button, Input } from '@/ui';
import { renderDate } from '@/utils';
import { TableColumnsType } from 'antd';
import { TFunction } from 'i18next';
import { ChangeEvent } from 'react';
import { StyledSearchWrapper } from '../components';
import { StyledLogoIcon } from '../components/Styled';

export const getBrandsTableConfig = (
  t: TFunction,
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void,
): TableColumnsType<IBrand> => [
  {
    title: t('label_logo'),
    dataIndex: 'logo',
    key: 'logo',
    render: (_, record) => {
      const files = record.files;
      const logoUrl = files?.[0]?.file?.url;
      if (!logoUrl) return null;
      return (
        <StyledLogoIcon>
          <img src={logoUrl} alt="logo" />
        </StyledLogoIcon>
      );
    },
  },
  {
    title: (
      <StyledSearchWrapper>
        <span>{t('label_code')}</span>
        <Input onChange={onChange} size="small" placeholder={t('placeholder_search')} />
      </StyledSearchWrapper>
    ),
    dataIndex: 'code',
    key: 'code',
    render: (code: string) => code,
  },
  {
    title: t('label_name'),
    dataIndex: 'labels',
    key: 'label',
    render: (_, record) => record?.labels?.en_US,
  },
  {
    title: t('label_updated_at'),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (updatedAt: string) => renderDate(updatedAt, DateFormats.DATE_WITH_TIME),
  },
  {
    title: t('channel_table_columns_action'),
    dataIndex: 'action',
    key: 'action',
    render: () => (
      <Button type="primary" size="small" block>
        {t('edit')}
      </Button>
    ),
  },
];
