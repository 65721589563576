import { IAdvantage, IUpdateCategoryDTO } from '@/models/category.model';
import { TUpdateCategoryFormValues, UpdateCategoryFieldNames } from '../helpers';

class UpdateCategoryDTO implements IUpdateCategoryDTO {
  id: string;

  advantages?: IAdvantage[] | null;

  image?: Blob | string;

  icon?: Blob | string;

  video?: Blob | string | null;

  thumbnail?: string | Blob;

  description?: string | null;

  seoBlocks?: string[] | null;

  seo?: {
    title?: string | null;
    description?: string | null;
    keywords?: string[] | null;
    robots?: string | null;
    author?: string | null;
    ogTitle?: string | null;
    ogDescription?: string | null;
    ogImage?: string | null;
    ogUrl?: string | null;
  };

  keywords?: string[];

  constructor(data: TUpdateCategoryFormValues & { id: string }) {
    this.id = data.id;
    this.description = data.description ?? null;
    this.seo = {
      title: data[UpdateCategoryFieldNames.SEO_TITLE] || null,
      description: data[UpdateCategoryFieldNames.SEO_DESCRIPTION] || null,
      keywords: data[UpdateCategoryFieldNames.SEO_KEYWORDS]?.length
        ? data[UpdateCategoryFieldNames.SEO_KEYWORDS]
        : null,
      robots: data[UpdateCategoryFieldNames.SEO_ROBOTS] || null,
      author: data[UpdateCategoryFieldNames.SEO_AUTHOR] || null,
      ogTitle: data[UpdateCategoryFieldNames.SEO_OG_TILE] || null,
      ogDescription: data[UpdateCategoryFieldNames.SEO_OG_DESCRIPTION] || null,
      ogImage: data[UpdateCategoryFieldNames.SEO_OG_IMAGE] || null,
      ogUrl: data[UpdateCategoryFieldNames.SEO_OG_URL] || null,
    };
    this.advantages = data.advantages ?? null;

    this.seoBlocks = data.seoBlocks ?? null;

    if (typeof data.image !== 'string') {
      this.image = data.image;
    }

    if (typeof data.icon !== 'string') {
      this.icon = data.icon;
    }

    if (typeof data.video !== 'string') {
      this.video = data.video;
    }

    if (typeof data.thumbnail !== 'string') {
      this.thumbnail = data.thumbnail;
    }
  }
}

export default UpdateCategoryDTO;
