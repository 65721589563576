import { RootState } from '@/store';
import { BrandsState, SLICE_NAME } from './models';
import { createSelector } from '@reduxjs/toolkit';

const brandsSelector = (state: RootState): BrandsState => state[SLICE_NAME];

export const selectBrands = createSelector(brandsSelector, (state: BrandsState) => state.brands);

export const selectIsLoading = createSelector(brandsSelector, (state: BrandsState) => state.isLoading);

export const selectError = createSelector(brandsSelector, (state: BrandsState) => state.error);

export const selectPagination = createSelector(brandsSelector, (state: BrandsState) => state.pagination);
