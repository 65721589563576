import { Form } from '@/components';
import { pickThemeFontStyles } from '@/theme';
import { Table } from '@/ui';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledTitle = styled.h2`
  ${pickThemeFontStyles('24', '32', 'BOLD')};
`;

export const StyledSyncForm = styled(Form)`
  max-width: 35rem;
  display: grid;
  grid-template-columns: 1fr auto;
  margin-bottom: 1rem;
`;

export const StyledTable = styled(Table)`
  max-width: 50rem;
` as typeof Table;
