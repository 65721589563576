import { styled } from 'styled-components';

export const StyledSearchWrapper = styled.div`
  display: flex;
  gap: 20%;
  align-items: center;
  flex-wrap: wrap;

  input {
    flex-grow: 1;
    width: auto !important;
  }
`;

export const StyledLogoIcon = styled.div`
  width: 90px;
  height: 90px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const StyledLogo = styled.div`
  max-width: 400px;
  max-height: 400px;
  margin-bottom: 20px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
