// Globals
import { useTranslation } from 'react-i18next';

// Components
import { FormItem, IFormInstance } from '@/components';
import { Button, Select } from '@/ui';
import { StyledDatePicker, StyledForm, StyledTitle } from './ProductSyncFormStyles';

// Helpers
import { getChannelsOptions, getSyncOptions, SyncFormFormFields, TSyncFormValues } from '../../helpers';
import { useSyncFormRules } from '../../hooks';

// Models
import { IChannel } from '@/models/channel.model';

type TProductSyncFormProps = {
  productSyncForm: IFormInstance;
  onSubmit: (values: TSyncFormValues) => void;
  channels: IChannel[];
};

const ProductSyncForm = ({ onSubmit, productSyncForm, channels }: TProductSyncFormProps) => {
  const { t } = useTranslation();
  const rules = useSyncFormRules();
  const syncTypeOptions = getSyncOptions(t);
  const channelsOptions = getChannelsOptions(channels);

  return (
    <div>
      <StyledTitle>{t('title_products_sync')}</StyledTitle>
      <StyledForm onFinish={onSubmit} form={productSyncForm}>
        <FormItem name={SyncFormFormFields.TYPE} label={t('label_what_to_sync')} rules={rules[SyncFormFormFields.TYPE]}>
          <Select options={syncTypeOptions} allowClear />
        </FormItem>
        <FormItem
          name={SyncFormFormFields.CHANNEL}
          label={t('label_channels')}
          rules={rules[SyncFormFormFields.CHANNEL]}
        >
          <Select isMultiselect options={channelsOptions} allowClear />
        </FormItem>
        <FormItem
          name={SyncFormFormFields.UPDATED_FROM}
          label={t('label_date_from')}
          rules={rules[SyncFormFormFields.UPDATED_FROM]}
        >
          <StyledDatePicker allowClear />
        </FormItem>
        <FormItem
          name={SyncFormFormFields.UPDATED_UNTIL}
          label={t('label_date_to')}
          rules={rules[SyncFormFormFields.UPDATED_UNTIL]}
        >
          <StyledDatePicker allowClear />
        </FormItem>
        <Button block type="primary" htmlType="submit">
          {t('button_sync_products')}
        </Button>
      </StyledForm>
    </div>
  );
};

export default ProductSyncForm;
