// Globals
import { CopyOutlined } from '@ant-design/icons';
import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

// Store
import { useAppDispatch, useAppState } from '@/store';

// Modules
import { selectChannel, selectChannels } from '@/modules/Channels';
import { Header } from '@/modules/Layout';

// Components
import { Modal, useForm } from '@/components';
import { CheckIcon, PenIcon } from '@/icons';
import { Button, Spinner } from '@/ui';
import { GeneralSettingsBulkForm, GeneralSettingsForm, GeneralSettingsList } from '../../components';
import { StyledButtons, StyledSettingsWrapper } from './GeneralSettingsContainerStyles';

// Helpers
import { useBoolean } from '@/hooks';
import { BulkUpdateGeneralSettingsDTO, UpdateGeneralSettingsDTO } from '../../dtos';
import { bulkUpdateSettings, updateGeneralSettings } from '../../feature';
import {
  mapBulkGeneralInitialFromValues,
  mapGeneralInitialFromValues,
  TGeneralSettingsBulkFormValues,
  TGeneralSettingsFormValues,
} from '../../helpers';
import { useGetSettings } from '../../hooks';

// Models
import { ISetting } from '@/models/settings.model';

enum Layout {
  LIST = 'list',
  FORM = 'form',
  BULK = 'bulk',
}

const GeneralSettingsContainer = () => {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();
  const [query, setQuery] = useSearchParams({ layout: Layout.LIST });
  const layout = query.get('layout') || Layout.LIST;

  const { value: modalOpen, setTrue: openModal, setFalse: closeModal } = useBoolean();
  const channels = useAppState(selectChannels);
  const currentChannel = useAppState(selectChannel);
  const { settings, isLoading } = useGetSettings(currentChannel?.slug);

  const [form] = useForm();

  const handleEditSettings = () => {
    setQuery({ layout: Layout.FORM });
    if (settings) form.setFieldsValue(mapGeneralInitialFromValues(settings));
  };

  const handleBulkEditSettings = () => {
    setQuery({ layout: Layout.BULK });
    if (settings) form.setFieldsValue(mapBulkGeneralInitialFromValues(settings));
  };

  const handleConfirm = async () => {
    try {
      await form.validateFields();
      openModal();
    } catch (error) {
      console.error(error);
    }
  };

  const handleBulkFinish = useCallback(
    async (values: Partial<TGeneralSettingsBulkFormValues>) => {
      if (!settings || !currentChannel) return;
      const dto = new BulkUpdateGeneralSettingsDTO(values);
      try {
        await dispatch(bulkUpdateSettings(dto)).unwrap();
        setQuery({ layout: Layout.LIST });
        form.resetFields();
        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
    [closeModal, currentChannel, dispatch, form, setQuery, settings],
  );

  const handleFinish = useCallback(
    async (values: Partial<TGeneralSettingsFormValues>) => {
      if (!settings || !currentChannel) return;
      const dto = new UpdateGeneralSettingsDTO({ ...values, channelConfig: currentChannel, uuid: settings.id });
      try {
        await dispatch(updateGeneralSettings(dto)).unwrap();
        setQuery({ layout: Layout.LIST });
        form.resetFields();
        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
    [closeModal, currentChannel, dispatch, form, setQuery, settings],
  );

  const getContent = useCallback(
    (setting: ISetting) => {
      switch (layout) {
        case Layout.BULK:
          return (
            <GeneralSettingsBulkForm
              form={form}
              onFinish={handleBulkFinish}
              initialValues={mapBulkGeneralInitialFromValues(setting)}
              channels={channels ?? []}
            />
          );
        case Layout.FORM:
          return (
            <GeneralSettingsForm
              form={form}
              onFinish={handleFinish}
              initialValues={mapGeneralInitialFromValues(setting)}
            />
          );
        case Layout.LIST:
        default:
          return (
            <StyledSettingsWrapper>
              <GeneralSettingsList
                adminEmails={setting.adminEmails}
                copyright={setting.copyright}
                popularProductIds={setting.popularProductIds}
                channel={setting.channel}
                googleTagManagement={setting.googleTagManagement}
                phones={setting.phones}
                smtp={setting.smtp}
                contactInfo={setting.contactInfo}
                supportInfo={setting.supportInfo}
                popularCategoriesIds={setting.popularCategoriesIds}
                topPicksOfTheMonthProductIds={setting.topPicksOfTheMonthProductIds}
                homePageBlogPostSlug={setting.homePageBlogPostSlug}
                newMachinesCategory={setting.newMachinesCategory}
                ogSiteName={setting.ogSiteName}
                monthlySpecialCategory={setting.monthlySpecialCategory}
              />
            </StyledSettingsWrapper>
          );
      }
    },
    [channels, form, handleBulkFinish, handleFinish, layout],
  );

  return (
    <>
      <Header title={t('menu_general_settings')} suptitle={t('menu_website_management')}>
        <StyledButtons>
          {layout === Layout.LIST && (
            <>
              <Button type="primary" loading={isLoading} onClick={handleBulkEditSettings} icon={<CopyOutlined />}>
                {t('copy_to_bulk')}
              </Button>
              <Button type="primary" loading={isLoading} onClick={handleEditSettings} icon={<PenIcon />}>
                {t('btn_edit')}
              </Button>
            </>
          )}
          {layout !== Layout.LIST && (
            <Button type="primary" loading={isLoading} onClick={handleConfirm} icon={<CheckIcon />}>
              {t('btn_save')}
            </Button>
          )}
        </StyledButtons>
      </Header>
      <Spinner spinning={isLoading}>{settings && getContent(settings)}</Spinner>
      <Modal visible={modalOpen} onOk={form.submit} onCancel={closeModal} title={t('modal_title_confirm')} withFooter>
        <Trans
          i18nKey="modal_text_confirm"
          values={{ channel: currentChannel?.name, context: query.get('layout') }}
          components={{
            strong: <strong />,
          }}
        />
      </Modal>
    </>
  );
};

export default GeneralSettingsContainer;
