import { selectChannel } from '@/modules/Channels';
import { useAppDispatch, useAppState } from '@/store';
import { useEffect, useMemo } from 'react';
import { GetBrandsDTO } from '../dtos';
import { getBrands, selectBrands, selectError, selectIsLoading, selectPagination } from '../feature';

export const useGetBrands = () => {
  const dispatch = useAppDispatch();
  const currentChannel = useAppState(selectChannel);
  const brands = useAppState(selectBrands);
  const isLoading = useAppState(selectIsLoading);
  const error = useAppState(selectError);
  const pagination = useAppState(selectPagination);

  useEffect(() => {
    if (!currentChannel) return;
    const dto = new GetBrandsDTO({
      channelId: currentChannel.id,
    });

    dispatch(getBrands(dto));
  }, [currentChannel, dispatch]);

  return useMemo(() => ({ brands, isLoading, error, pagination }), [brands, isLoading, error, pagination]);
};
