import { IApiError } from '@/models/apiError.model';
import { IBrand } from '@/models/brands.model';
import { IPagination } from '@/models/pagination.model';

export const SLICE_NAME = 'brands';

export interface BrandsState {
  brands: IBrand[] | null;
  pagination: IPagination | null;
  isLoading: boolean;
  error: IApiError | null;
}

export const initialState: BrandsState = {
  brands: null,
  pagination: null,
  isLoading: false,
  error: null,
};
