import { httpClient, ApiResponse, convertModelToFormData } from '@/utils';
import {
  staticBrandsEndpoints,
  IGetBrandsDTO,
  IGetBrandsResponse,
  IUploadBrandLogoDTO,
  getBrandsEndpoints,
} from '@/models/brands.model';

class BrandsAPI {
  static getBrands(params: IGetBrandsDTO): ApiResponse<IGetBrandsResponse> {
    const { GET_BRANDS } = staticBrandsEndpoints;
    return httpClient.get<IGetBrandsResponse>(GET_BRANDS, {
      params,
    });
  }

  static uploadBrandLogo({ id, channelId, ...data }: IUploadBrandLogoDTO): ApiResponse<IGetBrandsResponse> {
    const { UPDATE_BRAND } = getBrandsEndpoints({ id });

    const formData = convertModelToFormData(data);

    return httpClient.patch<IGetBrandsResponse>(UPDATE_BRAND, formData, {
      params: { channelId },
    });
  }
}

export default BrandsAPI;
