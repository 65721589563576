import { paginationFields } from '@/models/pagination.model';
import { showApiErrors } from '@/utils';
import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import pick from 'lodash/pick';
import { getBrands, uploadBrandLogo } from './actionCreators';
import { BrandsState, SLICE_NAME, initialState } from './models';

export const brandsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBrands.fulfilled, (state: BrandsState, action) => {
        const { payload } = action;
        state.isLoading = false;
        state.brands = payload.brands;
        state.pagination = pick(payload, paginationFields);
      })
      .addCase(uploadBrandLogo.fulfilled, (state: BrandsState) => {
        state.isLoading = false;
      })
      .addMatcher(isPending(getBrands, uploadBrandLogo), (state: BrandsState) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(isRejected(getBrands, uploadBrandLogo), (state: BrandsState, action) => {
        const { error } = action;
        state.isLoading = false;
        state.error = error;
        showApiErrors(error);
      });
  },
});
