export enum ROUTES {
  HOME = '/',
  GENERAL_SETTINGS = '/general-settings',
  UI_SETTINGS = '/ui-settings',
  SHIPPING_PRICES = '/shipping-prices',
  SIGN_IN = '/sign-in',
  SIGN_UP = '/sign-up',
  PAGES_MANAGEMENT = '/pages-management',
  REVIEWS = '/reviews',
  SEO = '/seo',
  BRANDS = '/brands',
  SITE_MAP = '/site-map',
  CHANNELS = '/channels',
  NOT_FOUND = '*',
  REDIRECTS = '/redirects',
  METATAGS_TEMPLATES = '/metatags-templates',
  ROBOTS_TXT = '/robots-txt',
  VARIABLES = '/variables',
  FAQS = '/faqs',
  CATEGORIES = '/categories',
  AKENEO = '/akeneo',
  BLOG = '/blog',
}
