// GLobals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { FormItem } from '@/components';
import { Input, Select } from '@/ui';

// Helpers
import { BlogPostFormFields } from '../../helpers/types';

const PostSeo = () => {
  const { t } = useTranslation(['common']);

  return (
    <>
      <FormItem label={t('update_page_form_seo_title')} name={[BlogPostFormFields.SEO, 'title']}>
        <Input />
      </FormItem>
      <FormItem label={t('update_page_form_seo_description')} name={[BlogPostFormFields.SEO, 'description']}>
        <Input />
      </FormItem>
      <FormItem label={t('update_page_form_seo_keywords')} name={[BlogPostFormFields.SEO, 'keywords']}>
        <Select dropdownStyle={{ display: 'none' }} mode="tags" suffixIcon={null} />
      </FormItem>
      <FormItem label={t('update_page_form_seo_robots')} name={[BlogPostFormFields.SEO, 'robots']}>
        <Input />
      </FormItem>
      <FormItem label={t('update_page_form_seo_author')} name={[BlogPostFormFields.SEO, 'author']}>
        <Input />
      </FormItem>
      <FormItem label={t('update_page_form_seo_ogTitle')} name={[BlogPostFormFields.SEO, 'ogTitle']}>
        <Input />
      </FormItem>
      <FormItem label={t('update_page_form_seo_ogDescription')} name={[BlogPostFormFields.SEO, 'ogDescription']}>
        <Input />
      </FormItem>
      <FormItem label={t('update_page_form_seo_ogImage')} name={[BlogPostFormFields.SEO, 'ogImage']}>
        <Input />
      </FormItem>
      <FormItem label={t('update_page_form_seo_ogUrl')} name={[BlogPostFormFields.SEO, 'ogUrl']}>
        <Input />
      </FormItem>
    </>
  );
};

export default PostSeo;
