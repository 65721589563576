import { IPaginationDTO, IPaginationResponse } from '@/models/pagination.model';

export enum MetatagsEndpoints {
  ROOT = '/metatags',
}

export const staticMetatagsEndpoints = {
  GET_METATAGS: MetatagsEndpoints.ROOT,
  CREATE_METATAG: MetatagsEndpoints.ROOT,
};

export const getMetatagsEndpoints = ({ id }: { id: string }) => ({
  UPDATE_METATAG: `${MetatagsEndpoints.ROOT}/${id}`,
  DELETE_METATAG: `${MetatagsEndpoints.ROOT}/${id}`,
});

export interface IMetaTags {
  link: string;
  channelId: number;
  title: string;
  description: string;
  keywords?: string[];
  robots: string;
  author: string;
  ogTitle: string;
  ogDescription: string;
  ogImage: string;
  ogUrl: string;
  id: string;
}

// ============== DTO ==============

export type IGetMetatagsDTO = IPaginationDTO & {
  search?: string;
  channelId?: number;
};

export type IUpdateMetatagsDTO = Partial<IMetaTags> & {
  id: string;
};

export type IDeleteMetatagsDTO = {
  id: string;
};

export type ICreateMetatagsDTO = Omit<IMetaTags, 'id'>;

// ============== Response ==============

export type ICreateMetatagResponse = IMetaTags;

export type IUpdateMetatagResponse = IMetaTags;

export type IDeleteMetatagResponse = IMetaTags;

export type IGetMetatagsResponse = { metatags: IMetaTags[] } & IPaginationResponse;
